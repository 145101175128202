export default function SciteWidget(props) {
  return (
    <div
      className="scite-badge"
      data-doi={props.doi}
      data-layout="horizontal"
      data-show-zero="true"
      data-small="false"
      data-show-labels="false"
      data-tally-show="true"
    ></div>
  );
}
