import "./App.css";
import "@aws-amplify/ui-react/styles.css";

import { API, Amplify, graphqlOperation } from "aws-amplify";
import { AmplifyProvider, Card, Grid } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";

import SciteWidget from "./SciteWidget";
import SearchForm from "./SearchForm";
import awsconfig from "./aws-exports";
import logo from "./logo.ico";
import singularisLogo from "./singularis-logo.png";

Amplify.configure(awsconfig);

function App() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.scite.ai/badge/scite-badge-latest.min.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <AmplifyProvider>
      <div className="App">
        <Grid
          columnGap="0.5rem"
          rowGap="0.5rem"
          templateColumns="1fr 1fr 1fr"
          templateRows="15rem 3fr 15rem"
        >
          <Card columnStart="1" columnEnd="-1">
            <img src={singularisLogo} width="300px" height="auto" />
            {/* <SciteWidget doi='10.1073/pnas.74.12.5463' /> */}
          </Card>
          <Card columnStart="2" columnEnd="3">
            <SearchForm style={{ fontFamily: "Roboto, sans-serif" }} />
          </Card>
          <Card columnStart="1" columnEnd="-1"></Card>
        </Grid>
      </div>
    </AmplifyProvider>
  );
}

export default App;
