import { API, graphqlOperation } from "aws-amplify";
import { Pagination, SearchField } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";

import PulseLoader from "react-spinners/PulseLoader";
import SearchResults from "./SearchResults";
import { searchOpenAlex } from "./graphql/queries";

export default function SearchForm() {
  // const items = [
  //  { title: "first result", author: 'John Doe', description: "Some cool article" },
  //  { title: "second result", author: 'Alisa May', description: "blah blah" },
  // ];

  const [items, setItems] = useState({ results: [] });
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
      setTimeout(function() {window.__SCITE.insertBadges()}, 1000);
    //   window.scrollTo({top: 0});
  }, [isLoading]);


  function SearchOpenAlex(query, page) {
    console.log("Query: " + query);
    page = page > 0 ? page : 1;

    setIsLoading(true);
    setQuery(query)
    API.graphql(
      graphqlOperation(searchOpenAlex, { q: query, page: page })
    ).then((response) => {
      const items = response["data"]["searchOpenAlex"];
      console.log(items);
      setIsLoading(false);
      setItems(items);
      setPage(items.page);
    //   setTimeout(function() {window.__SCITE.insertBadges()}, 1000);
    });
  }

  return (
    <div>
      <SearchField
        label="Search"
        placeholder="Search titles, abstracts, text..."
        onSubmit={(query) => SearchOpenAlex(query, page)}
      />
      <PulseLoader color="#2596be" margin="10" loading={isLoading} />
      {!isLoading && <SearchResults results={items.results} />}
      {items.results.length > 0 && (
        <Pagination
          currentPage={items.page}
          totalPages={Math.round(items.count / items.per_page)}
          onChange={(newPage, prevPage) => SearchOpenAlex(query, newPage)}
          onNext={() => SearchOpenAlex(query, page + 1)}
          onPrevious={() => SearchOpenAlex(query, page - 1)}
          siblingCount={1}
        />
      )}
    </div>
  );
}
