/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchOpenAlex = /* GraphQL */ `
  query SearchOpenAlex($q: String, $page: Int) {
    searchOpenAlex(q: $q, page: $page) {
      results {
        doi
        title
        abstract
        authors
        year
        publisher
        cited_by_count
      }
      count
      per_page
      page
    }
  }
`;
export const getTestModel = /* GraphQL */ `
  query GetTestModel($id: ID!) {
    getTestModel(id: $id) {
      id
      name
      created_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTestModels = /* GraphQL */ `
  query ListTestModels(
    $filter: ModelTestModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        created_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTestModels = /* GraphQL */ `
  query SyncTestModels(
    $filter: ModelTestModelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTestModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        created_at
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
