import { Flex } from "@aws-amplify/ui-react";
import SciteWidget from "./SciteWidget";
import { SearchResult } from './ui-components';

function joinAuthors(authors) {
    let authorsJoined = "";

    const conj = authors.length > 3 ? ', ..., ' : ', ';
    if(authors.length > 0 && authors.length > 2) {
        authorsJoined = authors.slice(0,2).join(', ') + conj + authors[authors.length - 1];
    } else if(authors.length > 0) {
        authorsJoined = authors.join(', ')
    }

    return authorsJoined;
}

function stripHTMLTags(s) {
    return s.replace(/(<([^>]+)>)/ig, '');
}

function removeElsevierAbstract(doi, abstract) {
    if(abstract == null)
        return "...";

    abstract = stripHTMLTags(abstract);
    if(doi != null && doi.includes("10.1016")) {
        return "Abstract for this article was removed due to copyright removal request by a publisher."
    } else if(abstract.length> 500) {
        return abstract.split(/\s+/).slice(0, 100).join(" ") + '...';
    } else {
        return abstract;
    }
}

function getWidget(doi) {
    if (doi == null || doi.length < 12)
        return;
    else
        return <SciteWidget doi={doi.replace('https://doi.org/', '')} />;
}

export default function SearchResults({results = []}) {

   return (
            results.map(result => (
                    <Flex 
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        position="relative"
                        padding="5px 0px 5px 0px"
                        backgroundColor="rgba(255,255,255,1)">
                    <SearchResult
                        Title={<a href={result.doi}>{result.title}</a>}
                        Authors={joinAuthors(result.authors) + ' - ' + result.publisher + ', ' + result.year}
                        Abstract={removeElsevierAbstract(result.doi, result.abstract)}
                        CitedBy={'Cited by ' + result.cited_by_count}
                        badge={getWidget(result.doi)}
                        style={{marginTop:"2em"}}
                        />
                </Flex>

            ))
        
    )
}